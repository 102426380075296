@import-normalize;
@import "~react-leaflet-markercluster/dist/styles.min.css";

/* скрываем флаг Украины на картах */
.leaflet-attribution-flag {
  display: none;
}

.sorter-badge .ant-badge-count {
  margin-left: 8px;
  color: #999;
  background-color: #fff;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.ant-menu-item-selected .sorter-badge .ant-badge-count {
  margin-left: 8px;
  color: #fff;
  background-color: var(--antd-wave-shadow-color);
  box-shadow: 0 0 0 1px #fff inset;
}

.ant-typography {
  margin-bottom: 0 !important;
}

.ant-menu-item-selected {
  font-weight: bold;
}

.mapboxgl-popup-content {
  max-width: 400px;
  padding: 16px !important;
}

.mapbox-popup-button {
  padding-left: 0px;
}

@media (max-width: 768px) {
  .ant-back-top {
    margin-bottom: 10px;
  }

  .ant-picker-panels {
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .ant-modal {
    width: 100%;
    height: 100%;
    top: 0;
    max-width: 100%;
    margin: 0;
    padding-bottom: 0;
  }
  .ant-modal-header {
    padding-right: 55px;
  }
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-modal-body {
    flex: auto;
    padding: 8px;
  }

  .ant-page-header-heading-title h3 {
    font-size: 20px;
  }
  .ant-page-header-heading-left {
    margin: 0;
  }
}
