.appLayout {
  margin: 0 auto;
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .appLayout {
    max-width: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .appLayout {
    max-width: 100%;
    overflow: auto;
  }
}
